<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 18:02:24
 * @FilePath: \sdy_webpack1\src\pages\expertDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="expertDetail">
    <!-- 背景图 -->
    <!-- <div class="backgrpund_img"></div>
    <div class="blackDiv"></div> -->
    <!-- 内容 -->
    <div class="optionContainer">
      <!-- 科室内容 -->
      <div class="deptInfos">
        <!-- <h1 class="deptTitle">科室简介</h1> -->
        <div class="deptDescs">
          <div
            class="deptImgDiv"
            v-if="docterInfos.photo && docterInfos.photo.data"
          >
            <img :src="imgUrl + docterInfos.photo.data.attributes.url" alt />
          </div>
          <div class="imgDivNo" v-else>暂无照片</div>
          <div class="descTextDiv" v-if="docterType === '导师专栏'">
            <div class="txDiv titleDiv">
              <span class="title txTitle">
                {{ docterInfos.doctorName }}
              </span>
            </div>
            <div class="yjDiv titleDiv">
              <span class="text yjText">{{ docterInfos.mentorLevel }}</span>
            </div>
          </div>
          <div class="descTextDiv" v-else>
            <div class="txDiv titleDiv">
              <span class="title txTitle">
                {{ docterInfos.doctorName }}
              </span>
            </div>
            <div class="yjDiv titleDiv">
              <span class="title yjTitle">职称：</span>
              <span class="text yjText">{{ docterInfos.doctorTitle }}</span>
            </div>
            <div class="ksDiv titleDiv">
              <span class="title yjTitle">职务：</span>
              <span class="text yjText">{{ docterInfos.honor }}</span>
            </div>
            <div class="ksDiv titleDiv">
              <span class="title yjTitle">专长：</span>
              <span class="text zcText">{{ docterInfos.expertise }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 个人简介 -->
      <div class="deptZJContainer">
        <div class="zjTopInfos">
          <!-- <img class="titleImg" src="../assets/icons/detailzj.png" alt=""> -->
          <span class="title">个人简介</span>
        </div>
        <div class="zjIntInfoLists">
          <div v-html="docterInfos.introduce" class="descCont"></div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <FooterMob></FooterMob>
  </div>
</template>

<script>
import axios from "axios";
import FooterMob from "@/components/footerMob.vue";
import { config } from "../api/config";
import { IsPC } from "@/utils/agentFlag";
export default {
  name: "expertDetailMob",
  components: {
    FooterMob,
  },
  data() {
    return {
      imgUrl: "",
      doctorsId: "",
      docterInfos: {},
      docterType: "",
    };
  },
  created() {
    this.imgUrl = config().imgserve;
    if (IsPC()) {
      this.$router.push({
        path: "/expertDetail",
        query: {
          list: this.$route.query.list,
          id: this.$route.query.id,
        },
      });
      return;
    }
    this.docterType = this.$route.query.title;
    this.getDocDetail();
  },
  methods: {
    getDocDetail() {
      this.docterInfos = {};
      axios
        .get(
          config().urlServe +
            "/api/doctors/" +
            this.$route.query.id +
            "?populate[photo]=true"
        )
        .then((res) => {
          if (res.status === 200) {
            this.docterInfos = res.data.data.attributes;
            document.title =
              "新疆维吾尔自治区人民医院-" + this.docterInfos.doctorName;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>
<style>
.descCont img {
  max-width: 100% !important;
  height: auto !important;
}
</style>
<style lang="less" scoped>
.expertDetail {
  width: 100%;
  position: relative;
  margin-top: 35px;
  .backgrpund_img {
    position: absolute;
    background: #dff1f5;
    top: 0px;
    height: 50px;
    width: 100%;
    background-image: url("../assets/images/h_xj_bg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .blackDiv {
    height: 10px;
  }
  .optionContainer {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    text-align: left;
    .cursorSpan:hover {
      cursor: pointer;
      color: #049d98;
    }
    .positionDiv {
      font-size: 5px;
      margin-bottom: 10px;
    }
    .deptName {
      font-size: 7px;
      margin: 5px 0;
    }
    // 科室内容
    .deptInfos {
      width: 100%;
      padding: 5px 0px;
      // border-radius: 3px;
      // background: #049D98;
      // height: 110px;
      // box-shadow: 0px 0px 3px 2px #aaa;
      color: #fff;
      .deptTitle {
        margin-bottom: 5px;
      }
      .deptDescs {
        width: calc(100% - 10px);
        background: #dff1f5;
        border-radius: 3px;
        padding: 10px 5px;
        // height: 100px;
        // display: flex;
        // flex-wrap: nowrap;
        // justify-content: space-between;
        position: relative;
        .imgDivNo {
          width: 100%;
          line-height: 60px;
          text-align: center;
          font-size: 6px;
          color: #d1d0d0;
          border: 0.2px solid #d1d0d0;
        }
        .descTextDiv {
          width: 100%;
          // margin-left: 10px;
          position: relative;
          .descText {
            height: 30px;
            box-sizing: border-box;
            line-height: 13px;
            font-size: 6px;
            color: #1e4743;
          }
          .timerDiv {
            position: absolute;
            bottom: 0px;
          }
          .txDiv {
            font-size: 10px;
          }
          .yjDiv,
          .ksDiv {
            display: flex;
            flex-wrap: nowrap;
            margin-top: 3px;
          }
          .title {
            display: inline-block;
            width: 10px;
            // text-align: center;
            height: 8px;
            line-height: 8px;
            border-radius: 3px;
            color: #1e4743;
            font-size: 4px;
          }
          .txTitle {
            font-size: 6px;
            // margin-bottom: 5px;
            width: 100%;
            font-weight: bold;
            // box-shadow: 2px 0px 10px 1px rgba(187, 188, 189, 0.4);
            margin-left: 0px;
          }
          .yjTitle {
            width: 30px;
            font-size: 6px;
          }
          .tmTitle {
            background-color: #dd5b25;
          }
          .text {
            font-size: 5px;
            color: #1e4743;
            line-height: 8px;
          }
          .txText {
            max-height: 8px;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .zcText {
            width: 100%;
            // height: 25px;
            line-height: 8px;
            // 多行省略
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 3;
            // overflow: hidden;
          }
          .yjText {
            width: 100%;
            // height: 20px;
            line-height: 8px;
            color: #1e4743;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            // margin-bottom: 28px;
            // max-height: 68px;
            // white-space: nowrap;
            // overflow: hidden;//文本超出隐藏
            // text-overflow: ellipsis;//文本超出省略号代替
          }
        }
        .deptImgDiv {
          width: 100%;
          // height: 100px;
          padding: 0px;
          // border-radius: 3px;
          position: relative;
          text-align: center;
          background: #fff;
          overflow: hidden;
          // box-shadow: 0px 0px 3px 2px #aaa;

          img {
            width: 100%;
          }
        }
      }
      .eqcodeDiv {
        position: absolute;
        top: 0px;
        right: 0px;
        img {
          width: 50px;
          margin-left: 10px;
        }
      }
    }
    // 个人简介
    .deptZJContainer {
      // margin-top: 15px;
      width: calc(100% - 10px);
      background: #dff1f5;
      border-radius: 3px;
      padding: 10px 5px;

      .zjTopInfos {
        width: 100%;
        position: relative;
        .titleImg {
          width: 15px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        span {
          display: inline-block;
        }
        .title {
          font-size: 8px;
          font-weight: bold;
          margin-top: 5px;
          color: #049d98;
        }
        .text {
          font-size: 5px;
          margin-left: 5px;
        }
      }
      .zjIntInfoLists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 5px;
        // overflow: hidden;
        overflow-x: auto;

        .descCont {
          line-height: 15px !important;
        }
      }
    }

    .deptContent {
      line-height: 7px;
      // margin-top: 20px;
      .descTitle {
        width: 170px;
        margin: 0 auto;
        font-size: 7px;
        text-align: center;
        font-weight: bold;
        line-height: 10px;
      }
      p {
        text-indent: 2ch;
        font-size: 5.5px;
        color: #1e4743;
        line-height: 10px;
      }
      .pTitle {
        font-weight: bold;
      }
      .divTitle {
        font-size: 5.5px;
        color: #1e4743;
        margin-top: 15px;
      }
      .paTitle {
        margin-top: 10px;
      }
      .descD,
      .timer {
        font-size: 5.5px;
        color: #1e4743;
        margin-top: 15px;
        text-align: right;
      }
      .descD {
        margin-top: 15px;
      }
      .timer {
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>